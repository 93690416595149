import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import propTypes from './propTypes';
import { formatDateString } from '../../utils/dateConverter';

const StyledRow = styled(Row)`
  margin: 10px;
  font-size: 16px;
`;

const StyledCol = styled(Col)`
  font-weight: bold;
`;

const SummaryRow = ({ title, value }) => (
  <StyledRow>
    <StyledCol lg="3" xl="2">
      {`${title}:`}
    </StyledCol>
    <Col>{value}</Col>
  </StyledRow>
);

SummaryRow.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
};

const SummaryGrid = ({ appId, summary }) => (
  <div>
    <SummaryRow title="App Id" value={appId} />
    <SummaryRow title="App Name" value={summary.name} />
    <SummaryRow title="Description" value={summary.description || '-'} />
    <SummaryRow title="Last Reload" value={formatDateString(summary.lastReloadTime)} />
    {!summary.isProductionApp && <SummaryRow title="Pushed to Production" value={formatDateString(summary.pushToProductionTime)} /> }
  </div>
);

SummaryGrid.propTypes = {
  summary: propTypes.summary,
  appId: PropTypes.string,
};

export default SummaryGrid;
